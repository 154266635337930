import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import BorrowingService from "../services/BorrowingService.js";
import AssetService from "../services/AssetService.js";
import AuthService from "../services/AuthService.js";
import Loading from "../components/Loading.js";

const Cart = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [detail, setDetail] = useState([]); 
  const [currentCart, setCurrentCart] = useState([]); 
  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        AuthService.parseToken(cookies?.token)
        .catch(() => {
            navigate('/login')
        })
        
        BorrowingService.getDetail(id, cookies?.token)
        .then(async (result) => {
            setDetail(result.data);      
            
            if(result.data.second_lecturer_email === null && result.data.status !== "Approved"){
                navigate('/')
            } else if(result.data.second_lecturer_email !== null && result.data.status !== "Approved" && result.data.second_status !== "Approved") {
                navigate('/')
            }
            
            if(new Date(result.data.check_out_date) <= new Date()){
                navigate('/')
            }
        })  
        .catch((error) => {
            console.log(error);
        });

        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            setCurrentCart(JSON.parse(storedCart));
        }
    }, []);

    const handleRemoveToCart = (id) => {
        setCurrentCart(prevCart => {
            const updatedCart = prevCart.filter(item => item.id !== id);
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            return updatedCart;
        });
    };

    const handleSubmit = () => {
        const data = {
            "greenlit_id": id,
            "assets": currentCart
        }
        setOpen(false);
        setLoading(true);
        AssetService.borrowAssets(data, cookies.token).then(() => {
            setLoading(false);
            navigate('/detail/'+id);
        });
    }

  return (
    <div className="relative">
      <div className="mx-[20px]">
        <Header setAuthorized={setAuthorized}/>
        <section className="home section">
            <div className="container flex flex-col items-center">
                <div className="overflow-auto w-full rounded-lg block">
                    <div className="flex flex-wrap justify-between items-center mb-3">
                        <div className="">
                            <h1 className="text-xl font-semibold my-1 underline">Cart</h1>  
                            <h1 className="text-sm font-semibold my-1">Start Date: {detail.check_out_date}</h1>  
                            <h1 className="text-sm font-semibold my-1">End Date: {detail.check_in_date}</h1>  
                        </div>
                        <div className="flex justify-center items-end mr-5">
                            <form className="relative w-max">
                                <div className="relative w-[450px] h-[80px]">
                                    <input type="text" placeholder="Search" className="absolute top-[20px] box-border h-[43px] px-[20px] text-lg rounded-full outline-none border-2 border-green-500 transition-all duration-1000 ease-in-out" style={{right: `${isActive ? '45px' : '20px'}`, width: `${isActive ? '350px' : '0'}`}}/>
                                    <div onClick={() => setIsActive(isActive => !isActive)} className={`absolute top-[10px] box-border w-[60px] h-[60px] bg-transparent-500 text-[#fff] px-[20px] cursor-pointer flex items-center outline-none text-lg rounded-full border-3 border-transparent-500 transition-all duration-1000 ease-in-out ${isActive ? 'right-[35px]' : 'right-[12px]'}`} style={{transform: isActive ? 'rotate(-360deg)' : 'none'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="#22c55e" className="w-6 h-6">
                                            <path d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                        </svg>
                                    </div>
                                </div>
                            </form>
                            <a href={`/assets/${id}`}>
                                <div className="relative h-[80px] flex justify-content items-center">
                                    <div className="box-border w-[43px] h-[43px] bg-transparent-500 text-[#fff] cursor-pointer flex items-center justify-center outline-none text-lg rounded-full border-2 border-green-500 transition-all duration-1000 ease-in-out ${isActive ? 'right-[40px]' : 'right-[17px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="#22c55e" className="w-5 h-5">
                                            <path d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                                        </svg>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <hr className="w-full border-[1px] mb-7"/>

                <div className="w-full flex justify-end pr-5">
                    <button className={`text-white ${currentCart.length === 0 ? 'bg-gradient-to-r from-gray-500 via-gray-600 to-gray-700 hover:cursor-not-allowed' : 'bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:cursor-pointer'} hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}  onClick={() => setOpen(true)} disabled={currentCart.length == 0}>Submit</button>
                </div>

                <div className="flex flex-wrap gap-10 my-8 justify-center items-center">
                    {currentCart.map((asset) => (
                        <div className="relative w-fit max-w-[280px] h-[35rem] bg-white border border-gray-200 rounded-lg shadow flex flex-col items-center" key={asset.id}>
                            <a href="#">
                                <img className="p-0 rounded-t-lg w-full max-h-[25rem]"  src={asset?.images[0]?.path ? asset?.images[0].path : '/assets/noImage.jpg'} alt="product image" />
                            </a>
                            <div className="px-5 py-5">
                                <div href="#">
                                    <h5 className="text-md font-semibold tracking-tight text-gray-500">#{asset.id} / {asset.product_number}</h5>
                                </div>
                                <div href="#">
                                    <h5 className="text-lg font-semibold tracking-tight text-gray-900">{asset.name}</h5>
                                </div>
                                <div href="#">
                                    <h5 className="text-sm font-semibold tracking-tight text-gray-400">Created: {asset.created_at}</h5>
                                </div>
                            </div>

                            {currentCart.some(item => item.id === asset.id) && (
                                <div onClick={() => handleRemoveToCart(asset.id)} className="text-red-500 font-medium rounded-lg text-sm px-2 py-1.5 text-center m-2 absolute bottom-1 right-1 cursor-pointer">Remove from Cart</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
      </div>
      {open && (
        <div className="fixed w-full h-screen flex justify-center items-center top-0 bg-black bg-opacity-50" onClick={() => setOpen(false)}>
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <div className="bg-white p-10 rounded-xl max-w-[90vw] flex flex-col items-center gap-5">
                <h1 className="text-3xl">Terms and Conditions</h1>
                <ul className="max-w-[80vw] max-h-[50vh] overflow-y-auto list-disc list-inside">
                    <li>You are scheduled to return this equipment by the date and time indicated on the first page of this form. Failure to return the equipment by that date will result in a 20% deduction from the student's overall score, unless permission is granted by BINUS University Film faculty member in charge in equipment check out. Please notify immediately BINUS International Film faculty member in charge if you know you will be late.</li>
                    <li>If you are checking out equipment for non-film course related purposes, you need to notify Film Studio Operational Staff in charge and fill in the necessary paper work. If you fail to return the equipment for three times, then you will get probation period of one semester where you cannot check out Film Program BINUS UNIVERSITY equipment.</li>
                    <li>Any equipment that is broken through negligence or accident must be replaced by the students who sign this form.</li>
                    <li>Equipment must be check out and returned by the full crew. The crew as a whole is responsible for all the equipment. Any loss or damage will be the collective reponsibility of the entire crew. If one member is abusing or mishandling the equipment, other crew member should notify the equipment room immediately so that they can be replaced.</li>
                    <li>BINUS University Film Faculty member in charge are the only one who can check out the equipment. Although we are entitled to give you equipment as booked properly, students must check out the equipment thoroughly before leaving the equipment room. BINUS International is not responsible for any damage or loss happen after student leave the equipment room.</li>
                    <li>All equipment must be transported in proper case provided by the students. You cannot check out the equipment unless you have the proper cases for all equipment.</li>
                    <li>Only BINUS University Film students that sign this check out form are allowed to use this equipment. If you are using crew from outside of BINUS University Film Program, please notify the Film Faculty member in charge before you check out the equipment.</li>
                    <li>Always cleans with professional cleaning materials. Do not try anything without asking our advice.</li>
                    <li>Please notify us if you are shooting in hazardous places.</li>
                    <li>Make sure you have all applicable permits.</li>
                    <li>Equipment room operating hour is Monday to Friday from 09:00AM - 5:00PM, Saturday from 09:00AM - 3:00PM. All check out and check in activities must be done in the operating hours unless posted otherwise.</li>
                    <li>Students need to book the equipment 0 day(s) in advance prior to check out.</li>
                </ul>
                <div onClick={handleSubmit} className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 w-full cursor-pointer">Submit</div>
            </div>
          </div>
        </div>
      )}
        {loading && (
            <div className="fixed w-full h-screen flex justify-center items-center top-0 bg-black bg-opacity-50">
                <div className="w-full h-screen absolute flex justify-center items-center transition-opacity duration-500">
                    <Loading />
                </div>
            </div>
        )}
    </div>
  );
};

export default Cart;
