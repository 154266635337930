import React, { useEffect, useState } from "react";
import LoginForm from "../components/LoginForm.js"
import Loading from "../components/Loading.js"
import AuthService from "../services/AuthService.js";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  useEffect(() => {
    AuthService.parseToken(cookies?.token)
    .then(() => {
      navigate('/')
    })
    .catch(() => {})
  }, []);

  return (  
    <div className="flex w-full h-screen">
        <div className="w-full flex items-center justify-center lg:w-4/4 bg-gradient-to-tr from-green-500 to-white-500">
            <LoginForm setLoading={setLoading}/>
        </div>
        {loading && (
          <div className="w-full h-screen absolute flex justify-center items-center transition-opacity duration-500">
            <Loading />
          </div>
        )}
    </div>
  );
};

export default Login;
