import Request from '../utils/Request';
import axios from 'axios';

class LecturerService {
    static async getAllLecturers(){
        try {
            const response = await axios.get(`${Request.backend}lecturers`);
            return response.data;
        } catch (error) {
            console.error('Error fetching lecturers:', error);
            return null;
        } 
    }
}

export default LecturerService;
