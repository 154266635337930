import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import { useCookies } from 'react-cookie';

const LoginForm = ({ setLoading }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [cookies, setCookie] = useCookies(['token']);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        AuthService.logIn({
            username: email,
            password: password,
        }).then(result => {
            setCookie('token', result.token, { expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) });
            setLoading(false);
            navigate('/');
        }).catch(error => {
            setErrorMsg("Invalid Credentials");
            console.log(error.message);
            setLoading(false);
        })
    };

  return (
    <div className="bg-white px-5 py-10 rounded-3xl border-2 border-gray-200 relative shadow-2xl">
        <img src="assets/LogoBinus.png" width="80rem" className="absolute right-5 top-5"/>
        <div className="w-full flex items-center justify-start">
            <h1 className="text-3xl font-semibold mt-4"><span className="text-green-500">Green</span><span className="text-gray-500">lit</span><span className="text-green-500">.</span></h1>
        </div>
        <div className="mt-4">
            <form onSubmit={handleLogin}>
                <div>
                    <label className="text-md font-medium text-gray-500">Email</label>
                    <input className="w-full border-2 border-gray-100 rounded-xl p-3 my-2 bg-transparent" placeholder="Example@binus.ac.id" type="email" onChange={(e) => setEmail(e.target.value)} value={email}/>
                </div>
                <div>
                    <label className="text-md font-medium text-gray-500">Password</label>
                    <input className="w-full border-2 border-gray-100 rounded-xl p-3 my-2 bg-transparent" placeholder="Enter your password" type="password" onChange={(e) => setPassword(e.target.value)} value={password}/>
                </div>
                <div>
                    <label className="text-md font-medium text-red-500">{errorMsg}</label>
                </div>
                <div className="mt-4 flex flex-col gap-y-4">
                    <button className={`active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 rounded-xl bg-green-500 text-white text-lg font-bold ${email.length < 1 || password.length < 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`} disabled={email.length < 1 || password.length < 1}>Log In</button>
                </div>
            </form>
            <p className="text-center text-gray-500 text-xs mt-2">
                &copy; Operations & System Development
            </p>
        </div>
    </div>
  );
};

export default LoginForm;
