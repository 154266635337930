import Request from '../utils/Request';
import axios from 'axios';

class AuthService {
    static async logIn(data) {
        try {
            const response = await axios.post(`${Request.backend}auth`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async parseToken(token) {
        try {
            const response = await axios.get(`${Request.backend}auth/parseToken`,{
                headers: {
                    Authorization: `${token}`
                }
            });    
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default AuthService;
