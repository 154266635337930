import axios from 'axios';


export default class Request{
    // static backend = 'http://10.35.148.49:4000/';
    static backend = 'https://greenlit.apps.binus.ac.id/api/';
    // static backend = 'http://127.0.0.1:5000/api/';

    static make(method,endpoint,data = {}, api_endpoint='bridge', token){
        console.log('API ENDPOINT: ' + api_endpoint);
        let header = {
            // 'content-type': 'multipart/form-data',
        };
        if(token !== null){
            token = token.replace('"','');
            header['Authorization'] = `${token}`;
        }
        data.endpoint = endpoint;
        data.method = method;
        
        return axios.post(this.backend + api_endpoint, data, {
            headers: header
        });
          
    }
}